import NextLink from 'next/link';
import { Box, Heading, HStack, LinkBox, LinkOverlay, Text } from '@chakra-ui/react';
import ProgressiveImage from './ProgressiveImage';
import { MdOutlinePhotoLibrary } from 'react-icons/md';

interface Props {
	coverPhoto: string;
	location: string;
	numTrips: number;
	hasGallery: boolean;
}

export default function Destination({ coverPhoto, hasGallery, location, numTrips }: Props) {
	const formattedLocation = location.toLocaleLowerCase().replace(/ /g, '-');

	return (
		<LinkBox
			as='article'
			borderRadius='16px'
			cursor='pointer'
			overflow='hidden'
			pos='relative'
			transition='all .2s'
			border='2px solid'
			borderColor='clay.100'
			_hover={{ borderColor: 'gold.400' }}
			_focus={{ borderColor: 'gold.400' }}>
			<ProgressiveImage
				borderRadius='16px'
				src={coverPhoto}
				width={320}
				height={240}
				alt={`View of ${location}`}
			/>
			<NextLink passHref href={`/d/${formattedLocation}`}>
				<LinkOverlay>
					<Box
						pos='absolute'
						w='100%'
						h='100%'
						top='0'
						zIndex={5}
						bgGradient='linear(to-b,transparent,black)'
					/>
					<Box zIndex={10} pos='absolute' bottom='16px' left='16px'>
						<Heading as='h3' fontSize='24px' color='clay.100' isTruncated fontWeight='black' mb='8px'>
							{location}
						</Heading>
						<HStack justifyContent='space-between' alignItems='center'>
							<Text fontSize='18px' color='clay.100' fontWeight='medium'>
								{numTrips} {numTrips > 1 ? 'Trips' : 'Trip'}
							</Text>
							{hasGallery && (
								<NextLink passHref href={`/g/${formattedLocation}`}>
									<HStack
										as='a'
										spacing={1}
										px={1}
										bg='rgba(0,0,0,.3)'
										rounded='full'
										color='#fff'
										border='1px'
										borderColor='transparent'
										backdropFilter='blur(3px)'
										transition='all .2s'
										_hover={{ borderColor: '#bdbdbd' }}>
										<MdOutlinePhotoLibrary size={12} />
										<Text fontSize='12px'>View Gallery</Text>
									</HStack>
								</NextLink>
							)}
						</HStack>
					</Box>
				</LinkOverlay>
			</NextLink>
		</LinkBox>
	);
}
