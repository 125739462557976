import { LayoutProps } from '@/types/ui';
import { Box } from '@chakra-ui/react';
import { useUI } from 'hooks/useUI';
import { useEffect, useRef } from 'react';
import { useScroll } from 'react-use';

export default function PageContent({ children }: LayoutProps): JSX.Element {
	const container = useRef<HTMLDivElement>(null);
	const { y } = useScroll(container);
	const { updateScrolling } = useUI();

	useEffect(() => {
		updateScrolling(y > 0);
	}, [y, updateScrolling]);

	useEffect(() => {
		document.addEventListener('click', (e) => {
			if (e.clientY < 51)
				container.current?.scrollTo({ top: 0, behavior: 'smooth' });
		});
	}, []);

	return (
		<Box
			h="100%"
			w="full"
			maxW="1500px"
			pb="24px"
			overflowY="auto"
			pos="relative"
			overscrollBehavior="contain"
			ref={container}
		>
			{children}
		</Box>
	);
}
