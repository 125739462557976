/* eslint-disable @typescript-eslint/no-explicit-any */
import Destination from '@/components/atoms/Destination';
import Header from '@/components/atoms/Header';
import PageContent from '@/components/atoms/Layouts/PageContent';
import { ExtendedDestination } from '@/types/extended';
import { Grid } from '@chakra-ui/react';
import { PrismaClient } from '@prisma/client';
import { GetStaticProps } from 'next';
import { NextSeo } from 'next-seo';
import safeJsonStringify from 'safe-json-stringify';
import { canonicalUrl, explore } from '../constants';

interface Props {
	data: ExtendedDestination[];
	gallery: Record<string, boolean>;
}

export default function Home({ data, gallery }: Props) {
	return (
		<>
			<NextSeo
				title='Explore'
				description='Explore destinations travelers around the world have been to.'
				canonical={canonicalUrl}
				openGraph={{
					type: 'website',
					url: canonicalUrl,
					title: 'Explore',
					description: 'Explore destinations travelers around the world have been to.',
					images: [{ url: explore }],
					site_name: 'WheredYouGo',
				}}
			/>
			<Header title='Explore' />
			<PageContent>
				<Grid
					px={{ base: '16px', md: '64px' }}
					pt='24px'
					gap='24px'
					templateColumns={{
						base: 'repeat( auto-fit, minmax(320px, 1fr) ))',
						md: 'repeat( auto-fit, 320px)',
					}}>
					{data?.map((el, idx) => (
						<Destination
							key={idx}
							hasGallery={gallery[el.location]}
							numTrips={el.trips.filter((trip) => !trip.draft).length}
							coverPhoto={el.coverPhoto}
							location={el.location}
						/>
					))}
				</Grid>
			</PageContent>
		</>
	);
}

export const getStaticProps: GetStaticProps = async () => {
	const prisma = new PrismaClient();

	const [destinations, photos] = await Promise.all([
		prisma.destination.findMany({
			orderBy: { updatedAt: 'desc' },
			include: { trips: { orderBy: { updatedAt: 'desc' } } },
		}),
		prisma.photo.findMany({
			where: { trip: { draft: false } },
			include: {
				trip: {
					select: { location: true },
				},
			},
		}),
	]);
	
	const gallery = photos.reduce((acc, photo) => {
		const { location } = photo.trip;
		if (!acc[location]) acc[location] = true;
		return acc;
	}, {} as Record<string, boolean>);

	const destinationsWithTrips = destinations.filter((destination) =>
		Boolean(destination.trips.filter((trip) => !trip.draft).length)
	);
	const data = JSON.parse(safeJsonStringify(destinationsWithTrips));
	return { props: { data, gallery } };
};
