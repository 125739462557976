import { Box, Flex } from '@chakra-ui/layout';
import { Heading, Skeleton, HStack, Text, SkeletonCircle } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useUI } from '../../hooks/useUI';
import Link from 'next/link';

interface Props {
	title: string;
	loading?: boolean;
	footer?: ReactNode;
	backlinks?: { label: string; link: string }[] | null;
	headerRight?: ReactNode;
	titleReplacement?: ReactNode;
}

export default function Header({
	title,
	footer,
	loading,
	backlinks,
	headerRight,
	titleReplacement,
}: Props): JSX.Element {
	const { scrolling } = useUI();

	return (
		<Box
			bg='clay.100'
			pt={{ base: '32px', md: '64px' }}
			pb='16px'
			w='full'
			maxW='1500px'
			zIndex='banner'
			pos='relative'
			transition='all .2s'>
			<Box
				w='full'
				h='2'
				pos='absolute'
				bottom='0'
				zIndex='banner'
				transition='all .2s'
				shadow={scrolling ? 'md' : 'none'}
			/>
			{backlinks && (
				<HStack px={{ base: '16px', md: '64px' }} alignItems='center'>
					{backlinks?.map((el, idx) => (
						<HStack key={idx}>
							<Link href={el.link} passHref>
								<Text
									as='a'
									cursor='pointer'
									rounded='sm'
									fontSize='lg'
									transition='all .2s'
									_hover={{ textDecoration: 'underline' }}
									fontWeight='medium'>
									{el.label}
								</Text>
							</Link>
							{idx !== backlinks.length - 1 && <Text ml='2'>/</Text>}
						</HStack>
					))}
				</HStack>
			)}
			<Flex px={{ base: '16px', md: '64px' }} justifyContent='space-between' alignItems='center'>
				<Skeleton borderRadius='md' isLoaded={!loading} startColor='gray.200' endColor='gray.300'>
					{titleReplacement ? (
						titleReplacement
					) : (
						<Heading as='h1' fontSize={{ base: '42px', md: '48px' }} fontWeight='black' noOfLines={2}>
							{title || 'Some title'}
						</Heading>
					)}
				</Skeleton>
				{loading ? <SkeletonCircle startColor='gray.200' endColor='gray.300' /> : headerRight}
			</Flex>
			{footer}
		</Box>
	);
}
